import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import SuccessImage from '../images/order-success.png';
import OrderSummary from '../components/CheckoutSummary';

export default function OrderSuccess() {
  return (
    <Container>
      <Left>
        <ImgStyled src={SuccessImage} />
        <D.H2 mods={[32, 'center']} style={{ marginBottom: 10 }}>
          Thanks for your order
        </D.H2>
        <D.P mods={[18, 'center']} style={{ marginBottom: 20 }}>
          You'll receive your pill within 48 hours of our pharmacy approving the
          prescription.
        </D.P>
        <D.P mods={[14, 'fade', 'center']}>
          The pharmacies working hours are 9am-5pm Monday to Friday, so if you
          order over the weekend it may take slightly longer
        </D.P>
      </Left>

      <Right>
        <OrderSummary successPage={true} />
      </Right>
    </Container>
  );
}

const Container = styled.div`
  background: #eaf5e8;
  height: 100vh;
  width: 100vw;
  animation: fadein 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    height: auto;
  }
`;

const Left = styled.div`
  background: #eaf5e8;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: auto;
  padding: 100px;
  @media only screen and (max-width: 500px) {
    padding: 50px;
    width: 100%;
  }
`;

const ImgStyled = styled.img`
  width: 256px;
  margin-bottom: 32px;
`;

const Right = styled.div`
  height: 100%;
  width: 50%;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    height: auto;
    padding: 50px;
    width: 100%;
  }
`;
